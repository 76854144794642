import React from "react";

export type InstallationStepProps = {
    index: number;
    title: string;
    children: React.ReactNode;
};

const InstallationStep: React.FunctionComponent<InstallationStepProps> = ({index, title, children}) => {
    return (
        <div className="installation-step">
            <div className="installation-step__title">
                <div>{index}</div>
                <span className="h3">{title}</span>
            </div>
            <div className="installation-step__content">
                {children}
            </div>
        </div>
    );
};

export default InstallationStep;
