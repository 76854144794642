import {ApiModel} from "../../../common/api/model/ApiModel";
import {pathAnalyticsHitsWithId} from "../../../utils/constants";
import {SitePrivacy} from "./privacy/SitePrivacy";
import {SiteStatus} from "./SiteStatus";
import {PathHelper} from "../../../common/utils/PathHelper";

export class Site extends ApiModel {
    id: number = 0;
    name: string = "";
    domain: string = "";
    privacy_policy: string = "";
    ga_tid: string = "";
    status: SiteStatus = SiteStatus.ACTIVE;
    tag: string = "";
    legacy_tag: string = "";
    privacy_configuration: SitePrivacy = new SitePrivacy();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            domain: this.domain,
            privacy_policy: this.privacy_policy,
            ga_tid: this.ga_tid,
            status: this.status,
            privacy_configuration: this.privacy_configuration.getJsonParameters()
        };
    }

    getAnalyticsRoute(): string {
        return PathHelper.buildPathWithId(pathAnalyticsHitsWithId, this.id);
    }
}
