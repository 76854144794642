import {SitePrivacyClickId} from "./SitePrivacyClickId";

export enum SitePrivacyUtmType {
    NO_ALTER = "NO_ALTER",
    REMOVE = "REMOVE",
}

export const SITE_PRIVACY_UTM_TYPES = [
    SitePrivacyClickId.NO_ALTER,
    SitePrivacyClickId.REMOVE
];
