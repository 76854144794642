import React, {FunctionComponent} from "react";
import {Alert, AlertSeverity, Button, Color, Icon, ModalContent, ModalNew, Tag, TagStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {ConfigurationModeName, CONFIGURATION_MODES} from "../../utils/ConfigurationMode";
import {ConfigurationModeInformation} from "../../utils/ConfigurationModeInformation";

type ModalConfigModeProps = {
    active: boolean;
    onClose: () => void;
    onSubmit: (mode: ConfigurationModeName) => void;
}

const ModalConfigMode: FunctionComponent<ModalConfigModeProps> = ({active, onClose, onSubmit}) => {
    const {t: textConfigurationMode} = useTranslation(TranslationPortalFile.CONFIGURATION_MODE);

    const CONFIGURATION_MODE_INFORMATIONS: { mode: ConfigurationModeName; info: ConfigurationModeInformation }[] = CONFIGURATION_MODES.map((mode) => {
        return {mode, info: textConfigurationMode(`modes.${mode}`, {returnObjects: true}) as ConfigurationModeInformation};
    });

    return (
        <ModalNew onClose={onClose} active={active} loading={false} keepMounted>
            <ModalContent>
                <div className="modal-create-config">
                    {CONFIGURATION_MODE_INFORMATIONS.map(({mode, info}) =>
                        <div key={mode} className="modal-create-config__mode">
                            <div className="modal-create-config__mode__content">
                                <div className="modal-create-config__mode__content__header">
                                    <Tag
                                        label={info.time}
                                        style={TagStyle.DEFAULT_CYAN_LIGHT}
                                        customColor={{borderColor: Color.CYAN_LIGHT}}
                                        icon={{name: "timer", outlined: true}}
                                    />
                                </div>
                                <div className="modal-create-config__mode__content__title">{info.title}</div>
                                <div className="modal-create-config__mode__content__description">{info.description}</div>
                                <div className="modal-create-config__mode__content__steps">
                                    {info.steps.map((step) =>
                                        <div key={step} className="modal-create-config__mode__content__steps__item">
                                            <Icon name="east" cssClass="modal-create-config__mode__content__steps__item__icon"/>
                                            <span>{step}</span>
                                        </div>
                                    )}
                                </div>
                                {info.indication &&
                                <div className="modal-create-config__mode__content__alert">
                                    <Alert severity={AlertSeverity.INFO} text={info.indication} fullWidth/>
                                </div>
                                }
                            </div>
                            <div>
                                <Button onClick={() => onSubmit(mode)} icon={{name: "east"}} reverse>{textConfigurationMode("setup")}</Button>
                            </div>
                        </div>
                    )}
                </div>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalConfigMode;
