import {CommonApiSession} from "../common/api/CommonApiSession";
import {RestSite} from "./rest/RestSite";
import {ApiClient} from "./ApiClient";
import {RestStat} from "./rest/RestStat";

export class ApiSession extends CommonApiSession {

    _client: ApiClient;

    private _restSite: RestSite | undefined;
    private _restStat: RestStat | undefined;

    constructor() {
        super();
        this._client = new ApiClient();
    }

    get restSite(): RestSite {
        return this._restSite !== undefined ? this._restSite : this._restSite = new RestSite(this._client);
    }

    get restStat(): RestStat {
        return this._restStat !== undefined ? this._restStat : this._restStat = new RestStat(this._client);
    }
}

export const session = new ApiSession();
