import React, {useEffect, useState} from "react";
import {MainContent, Wrapper} from "../common/component/widget";
import {Box, BoxProps, Button, ButtonLink, ButtonLinkStyle, ButtonSize, ButtonStyle, ContentBlock, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, LayoutRows, Paragraph} from "@sirdata/ui-lib";
import {MainHeader, NotificationCarousel} from "../common/component/snippet";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {session} from "../api/ApiSession";
import {News} from "../common/api/model/portal/News";
import {Locale} from "../common/utils/Locale";
import {UIEventManager} from "../common/utils/UIEventManager";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {Formatter} from "../common/utils/Formatter";

function Home() {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const [newses, setNewses] = useState<News[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const newses = await session.getNewses();
                setNewses(newses);
            } catch (e) {
                console.error("Failed to load dashboard", e);
            }
        })();
    }, []);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <LayoutRows cssClass={"home"}>
                    <FlexContent direction={FlexContentDirection.ROW} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <LayoutRows>
                            <NotificationCarousel cssClass="home__notification-carousel"/>
                        </LayoutRows>
                        <LayoutRows>
                            {newses.length > 0 &&
                                <ContentBlock
                                    header={{title: {label: textHome("latest_newses.title").toUpperCase(), icon: {name: "article"}}}}
                                    footer={{link: "https://news.sirdata.com/tag/cmp/", label: textHome("latest_newses.see_all_newses"), target: "_blank"}}
                                >
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass={"home__news"}>
                                        {newses.filter(({language}) => Locale.getCurrentLocale() === language).slice(0, 5).map(({title, url, pubDate}) =>
                                            <div key={title} className="news__item">
                                                <span className="news__item__date">{Formatter.formatDate(pubDate)}</span>
                                                <ButtonLink
                                                    cssClass="news__item__title"
                                                    style={ButtonLinkStyle.MIDNIGHT_LIGHT}
                                                    onClick={() => window.open(url, "_blank")}
                                                    reverseUnderline
                                                >
                                                    {title}
                                                </ButtonLink>
                                            </div>
                                        )}
                                    </Box>
                                </ContentBlock>
                            }
                            <ContentBlock header={{title: {label: textHome("support.title").toUpperCase(), icon: {name: "support"}}}}>
                                <Box cssClass={"home__support"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <div className="support__picture">
                                        <img src="/common/images/support/superdata.png" alt="Superdata"/>
                                    </div>
                                    <Paragraph withSpacing>{textHome("support.support_text")}</Paragraph>
                                    <Button size={ButtonSize.MEDIUM} style={ButtonStyle.PRIMARY_GREEN} onClick={() => UIEventManager.emit(ModalContactUiEvent, {})} icon={{name: "chat_bubble", outlined: true}}>
                                        {textHome("support.support_btn")}
                                    </Button>
                                </Box>
                            </ContentBlock>
                        </LayoutRows>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Home;
