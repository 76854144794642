import {FlexContent, FlexContentDirection} from "@sirdata/ui-lib";
import React from "react";

export type InstallationStepsProps = {
    children: React.ReactNode;
};

const InstallationSteps: React.FunctionComponent<InstallationStepsProps> = ({children}) => {
    return (
        <FlexContent direction={FlexContentDirection.COLUMN} cssClass="installation-steps">
            {children}
        </FlexContent>
    );
};

export default InstallationSteps;
