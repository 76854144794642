import React from "react";
import {FlexContent, FlexContentDirection, FlexContentSpacing, InputRadio} from "@sirdata/ui-lib";

type AnswerButtonsProps = {
    name: string;
    value: string | number;
    options: { value: string | number; label: string }[];
    onChange: (value: string | number) => void;
}

const AnswerButtons: React.FC<AnswerButtonsProps> = ({name, value, options, onChange}) => {
    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.XSMALL}
            cssClass="answer-buttons"
        >
            {options.map((it, index) => {
                const id = `${name}-radio-${index}`;
                return (
                    <div className="answer-buttons__button" key={id}>
                        <InputRadio
                            id={id}
                            value={it.value}
                            checked={value === it.value}
                            onChange={onChange}
                        />
                        <label htmlFor={id} className="answer-buttons__button__label">{it.label}</label>
                    </div>
                );
            })}
        </FlexContent>
    );
};

export default AnswerButtons;
