import {ButtonLinkBack, ButtonValidate, FieldBlock, FormLayoutButtons, FormLayoutRows, FormLayoutTitle, ModalContent, ModalNew, Select, ToggleSwitch} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {SitePrivacy} from "../../api/model/site/privacy/SitePrivacy";
import {SitePrivacyClickId} from "../../api/model/site/privacy/SitePrivacyClickId";
import {SitePrivacyField} from "../../api/model/site/privacy/SitePrivacyField";
import {SITE_PRIVACY_REFERER_VALUES, SitePrivacyReferer} from "../../api/model/site/privacy/SitePrivacyReferer";
import {SitePrivacyTransactionId} from "../../api/model/site/privacy/SitePrivacyTransactionId";
import {SITE_PRIVACY_TRUNCATE_IP_VALUES, SitePrivacyTruncateIp} from "../../api/model/site/privacy/SitePrivacyTruncateIp";
import {SITE_PRIVACY_URL_VALUES, SitePrivacyUrl} from "../../api/model/site/privacy/SitePrivacyUrl";
import {SITE_PRIVACY_USER_AGENT_VALUES, SitePrivacyUserAgent} from "../../api/model/site/privacy/SitePrivacyUserAgent";
import {SITE_PRIVACY_USER_ID_VALUES, SitePrivacyUserId} from "../../api/model/site/privacy/SitePrivacyUserId";
import {SitePrivacyUtm} from "../../api/model/site/privacy/SitePrivacyUtm";
import {SitePrivacyUtmField} from "../../api/model/site/privacy/SitePrivacyUtmField";
import {SitePrivacyUtmType} from "../../api/model/site/privacy/SitePrivacyUtmType";
import {Site} from "../../api/model/site/Site";
import {TranslationPortalFile} from "../../utils/constants";

type ModalEditSitePrivacyProps = {
    site: Site | undefined;
    onSubmit: () => Promise<void>;
    onClose: () => void;
    isCreationWorkflow: boolean;
    onBack: () => void;
};

const ModalEditSitePrivacy: FunctionComponent<ModalEditSitePrivacyProps> = ({site, onSubmit, onClose, isCreationWorkflow, onBack}) => {
    const {t: textSites} = useTranslation(TranslationPortalFile.SITES);

    const [currentSite, setCurrentSite] = useState<Site>(new Site());

    const handleChangeUtm = (utmField: SitePrivacyUtmField, checked: boolean) => {
        const value = getTargetValue(checked, SitePrivacyUtmType.REMOVE, SitePrivacyUtmType.NO_ALTER);
        const newSitePrivacyUtm = new SitePrivacyUtm({...currentSite.privacy_configuration.utm, [utmField]: value});
        const newSitePrivacy = new SitePrivacy({...currentSite.privacy_configuration});
        newSitePrivacy.utm = newSitePrivacyUtm;
        if ((value === SitePrivacyUtmType.REMOVE && (!currentSite.privacy_configuration.url || currentSite.privacy_configuration.url === SitePrivacyUrl.NO_ALTER)) ||
            (value === SitePrivacyUtmType.NO_ALTER && currentSite.privacy_configuration.url === SitePrivacyUrl.REMOVE_ALL_PARAMETERS)) {
            newSitePrivacy.url = SitePrivacyUrl.REMOVE_CLICK_ID_AND_UTM;
        }
        handleChangePrivacyConfiguration(newSitePrivacy);
    };

    const doUpdateSitePrivacy = (field: SitePrivacyField, value: string | number | undefined) => {
        const newSitePrivacy = new SitePrivacy({...currentSite.privacy_configuration, [field]: value});
        if (field === SitePrivacyField.URL) {
            switch (value) {
                case SitePrivacyUrl.NO_ALTER:
                    newSitePrivacy.utm = new SitePrivacyUtm();
                    newSitePrivacy.click_id = SitePrivacyClickId.NO_ALTER;
                    break;
                case SitePrivacyUrl.PSEUDONYMISE_ALL_PARAMETERS:
                case SitePrivacyUrl.REMOVE_CLICK_ID_AND_UTM:
                    newSitePrivacy.utm = new SitePrivacyUtm();
                    newSitePrivacy.click_id = SitePrivacyClickId.PSEUDONYMISE;
                    break;
                case SitePrivacyUrl.REMOVE_ALL_PARAMETERS:
                    newSitePrivacy.utm = new SitePrivacyUtm({
                        campaign: SitePrivacyUtmType.REMOVE,
                        content: SitePrivacyUtmType.REMOVE,
                        medium: SitePrivacyUtmType.REMOVE,
                        source: SitePrivacyUtmType.REMOVE,
                        term: SitePrivacyUtmType.REMOVE
                    });
                    newSitePrivacy.click_id = SitePrivacyClickId.REMOVE;
                    break;
                default:
                    break;
            }
        } else if (field === SitePrivacyField.CLICK_ID) {
            newSitePrivacy.url = SitePrivacyUrl.REMOVE_CLICK_ID_AND_UTM;
        }
        handleChangePrivacyConfiguration(newSitePrivacy);
    };

    const handleChangePrivacyConfiguration = (newSitePrivacy: SitePrivacy) => {
        setCurrentSite((prevState) => new Site({...prevState, privacy_configuration: newSitePrivacy}));
    };

    const getTargetValue = (checked: boolean, checkedValue?: any, uncheckedValue?: any) => {
        return checked ? checkedValue : uncheckedValue;
    };

    const handleSubmit = async (e?: FormEvent) => {
        if (!currentSite) return;
        if (e) e.preventDefault();

        try {
            await session.restSite.update(currentSite);
            await onSubmit();
        } catch (e) {
            console.error("Fail to update site:", e);
        }
    };

    useEffect(() => {
        if (!site) return;
        const newSite = new Site({
            ...site,
            privacy_configuration: new SitePrivacy({
                ...site.privacy_configuration,
                utm: new SitePrivacyUtm({...site.privacy_configuration?.utm})
            })
        });
        setCurrentSite(newSite);
    }, [site]);

    return (
        <ModalNew onClose={onClose} active={!!site}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>{textSites("modal.edit_privacy.title")}</FormLayoutTitle>
                    <FieldBlock label={textSites(`field.privacy.${SitePrivacyField.USER_ID}.title`)}>
                        <Select
                            value={currentSite.privacy_configuration.user_id}
                            options={SITE_PRIVACY_USER_ID_VALUES.map((it) => ({value: it, label: textSites(`field.privacy.${SitePrivacyField.USER_ID}.${it}`)}))}
                            onChange={(option) => doUpdateSitePrivacy(SitePrivacyField.USER_ID, option?.value as SitePrivacyUserId)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textSites(`field.privacy.${SitePrivacyField.TRUNCATE_IP}.title`)}>
                        <Select
                            value={currentSite.privacy_configuration.truncate_ip || SitePrivacyTruncateIp.TRUNCATE_ONE_BYTE}
                            options={SITE_PRIVACY_TRUNCATE_IP_VALUES.map((it) => ({value: it, label: textSites(`field.privacy.${SitePrivacyField.TRUNCATE_IP}.${it}`)}))}
                            onChange={(option) => doUpdateSitePrivacy(SitePrivacyField.TRUNCATE_IP, option?.value as SitePrivacyTruncateIp)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textSites(`field.privacy.${SitePrivacyField.USER_AGENT}.title`)}>
                        <Select
                            value={currentSite.privacy_configuration.user_agent || SitePrivacyUserAgent.NO_ALTER}
                            options={SITE_PRIVACY_USER_AGENT_VALUES.map((it) => ({value: it, label: textSites(`field.privacy.${SitePrivacyField.USER_AGENT}.${it}`)}))}
                            onChange={(option) => doUpdateSitePrivacy(SitePrivacyField.USER_AGENT, option?.value as SitePrivacyUserAgent)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textSites(`field.privacy.${SitePrivacyField.REFERER}.title`)}>
                        <Select
                            value={currentSite.privacy_configuration.referer || SitePrivacyReferer.NO_ALTER}
                            options={SITE_PRIVACY_REFERER_VALUES.map((it) => ({value: it, label: textSites(`field.privacy.${SitePrivacyField.REFERER}.${it}`)}))}
                            onChange={(option) => doUpdateSitePrivacy(SitePrivacyField.REFERER, option?.value as SitePrivacyReferer)}
                        />
                    </FieldBlock>
                    <ToggleSwitch
                        name={SitePrivacyField.TRANSACTION_ID}
                        label={textSites(`field.privacy.${SitePrivacyField.TRANSACTION_ID}`)}
                        checked={currentSite.privacy_configuration.transaction_id === SitePrivacyTransactionId.PSEUDONYMISE}
                        onChange={(value) => doUpdateSitePrivacy(SitePrivacyField.TRANSACTION_ID, getTargetValue(value, SitePrivacyTransactionId.PSEUDONYMISE, SitePrivacyTransactionId.NO_ALTER))}
                    />
                    <FieldBlock label={textSites(`field.privacy.${SitePrivacyField.URL}.title`)}>
                        <Select
                            value={currentSite.privacy_configuration.url || SitePrivacyUrl.NO_ALTER}
                            options={SITE_PRIVACY_URL_VALUES.map((it) => ({value: it, label: textSites(`field.privacy.${SitePrivacyField.URL}.${it}`)}))}
                            onChange={(option) => option && doUpdateSitePrivacy(SitePrivacyField.URL, option.value as SitePrivacyUrl)}
                        />
                    </FieldBlock>
                    <ToggleSwitch
                        name={SitePrivacyField.CLICK_ID}
                        label={textSites("field.privacy.click_id")}
                        checked={currentSite.privacy_configuration.click_id === SitePrivacyClickId.REMOVE}
                        onChange={(value) => doUpdateSitePrivacy(SitePrivacyField.CLICK_ID, getTargetValue(value, SitePrivacyClickId.REMOVE, SitePrivacyClickId.NO_ALTER))}
                    />
                    <ToggleSwitch
                        name={SitePrivacyUtmField.SOURCE}
                        label={textSites(`field.privacy.${SitePrivacyField.UTM}.${SitePrivacyUtmField.SOURCE}`)}
                        checked={currentSite.privacy_configuration.utm?.source === SitePrivacyUtmType.REMOVE}
                        onChange={(value) => handleChangeUtm(SitePrivacyUtmField.SOURCE, value)}
                    />
                    <ToggleSwitch
                        name={SitePrivacyUtmField.CAMPAIGN}
                        label={textSites(`field.privacy.${SitePrivacyField.UTM}.${SitePrivacyUtmField.CAMPAIGN}`)}
                        checked={currentSite.privacy_configuration.utm?.campaign === SitePrivacyUtmType.REMOVE}
                        onChange={(value) => handleChangeUtm(SitePrivacyUtmField.CAMPAIGN, value)}
                    />
                    <ToggleSwitch
                        name={SitePrivacyUtmField.MEDIUM}
                        label={textSites(`field.privacy.${SitePrivacyField.UTM}.${SitePrivacyUtmField.MEDIUM}`)}
                        checked={currentSite.privacy_configuration.utm?.medium === SitePrivacyUtmType.REMOVE}
                        onChange={(value) => handleChangeUtm(SitePrivacyUtmField.MEDIUM, value)}
                    />
                    <ToggleSwitch
                        name={SitePrivacyUtmField.CONTENT}
                        label={textSites(`field.privacy.${SitePrivacyField.UTM}.${SitePrivacyUtmField.CONTENT}`)}
                        checked={currentSite.privacy_configuration.utm?.content === SitePrivacyUtmType.REMOVE}
                        onChange={(value) => handleChangeUtm(SitePrivacyUtmField.CONTENT, value)}
                    />
                    <ToggleSwitch
                        name={SitePrivacyUtmField.TERM}
                        label={textSites(`field.privacy.${SitePrivacyField.UTM}.${SitePrivacyUtmField.TERM}`)}
                        checked={currentSite.privacy_configuration.utm?.term === SitePrivacyUtmType.REMOVE}
                        onChange={(value) => handleChangeUtm(SitePrivacyUtmField.TERM, value)}
                    />
                    <FormLayoutButtons>
                        {isCreationWorkflow && <ButtonLinkBack onClick={onBack}/>}
                        <ButtonValidate onClick={handleSubmit}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalEditSitePrivacy;
