export enum SitePrivacyClickId {
    NO_ALTER = "NO_ALTER",
    PSEUDONYMISE = "PSEUDONYMISE",
    REMOVE = "REMOVE"
}

export const SITE_PRIVACY_CLICK_ID_VALUES = [
    SitePrivacyClickId.NO_ALTER,
    SitePrivacyClickId.PSEUDONYMISE,
    SitePrivacyClickId.REMOVE
];
