import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SitePrivacyUtmType} from "./SitePrivacyUtmType";

export class SitePrivacyUtm extends ApiModel {
    campaign: SitePrivacyUtmType = SitePrivacyUtmType.NO_ALTER;
    content: SitePrivacyUtmType = SitePrivacyUtmType.NO_ALTER;
    medium: SitePrivacyUtmType = SitePrivacyUtmType.NO_ALTER;
    source: SitePrivacyUtmType = SitePrivacyUtmType.NO_ALTER;
    term: SitePrivacyUtmType = SitePrivacyUtmType.NO_ALTER;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            campaign: this.campaign,
            content: this.content,
            medium: this.medium,
            source: this.source,
            term: this.term
        };
    }
}

