export enum SitePrivacyReferer {
    NO_ALTER = "NO_ALTER",
    KEEP_DOMAIN = "KEEP_DOMAIN",
    REMOVE = "REMOVE",
}

export const SITE_PRIVACY_REFERER_VALUES = [
    SitePrivacyReferer.NO_ALTER,
    SitePrivacyReferer.KEEP_DOMAIN,
    SitePrivacyReferer.REMOVE
];
