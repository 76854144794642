import {routeParamId} from "../common/utils/constants";

/* ROUTES */
export const pathAnalyticsHits = "/analytics/hits";
export const pathAnalyticsHitsWithId = pathAnalyticsHits + routeParamId;
export const pathConfigurationMode = "/configuration-mode";
export const pathPrivacy = "/privacy";
export const pathSites = "/sites";
export const pathInstallation = pathSites + routeParamId + "/installation";
export const pathTermsAndConditions = "/terms-and-conditions";

/* TRANSLATIONS */
export enum TranslationPortalFile {
    ACCESS = "access",
    ANALYTICS = "analytics",
    CONFIGURATION_MODE = "configuration-mode",
    DATA_PROCESSING_AGREEMENT = "data-processing-agreement",
    HOME = "home",
    INSTALLATION = "installation",
    LEGAL_NOTICE = "legal-notice",
    LOGIN = "login",
    PRIVACY = "privacy",
    SITES = "sites",
    TERMS_AND_CONDITIONS = "terms-and-conditions",
    TRANSLATION = "translation"
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.ACCESS,
    TranslationPortalFile.ANALYTICS,
    TranslationPortalFile.CONFIGURATION_MODE,
    TranslationPortalFile.DATA_PROCESSING_AGREEMENT,
    TranslationPortalFile.HOME,
    TranslationPortalFile.INSTALLATION,
    TranslationPortalFile.LEGAL_NOTICE,
    TranslationPortalFile.LOGIN,
    TranslationPortalFile.PRIVACY,
    TranslationPortalFile.SITES,
    TranslationPortalFile.TERMS_AND_CONDITIONS,
    TranslationPortalFile.TRANSLATION
];
