import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalNew, Paragraph} from "@sirdata/ui-lib";
import {ConfigurationModeName} from "../../utils/ConfigurationMode";

type ModalSettingsExplanationProps = {
    active: boolean;
    configurationMode?: ConfigurationModeName;
    onContinue: () => void;
    onClose: () => void;
};

const ModalSettingsExplanation: FunctionComponent<ModalSettingsExplanationProps> = ({active, configurationMode, onContinue, onClose}) => {
    const {t: textSites} = useTranslation(TranslationPortalFile.SITES);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        {(textSites("modal.settings_explanation.paragraphs", {
                            returnObjects: true,
                            configurationModeSpecificIndication: textSites(`modal.settings_explanation.indication.${configurationMode || ConfigurationModeName.FLASH}`)
                        }) as string[]).map((paragraph, index) =>
                            <Paragraph key={`p-${index.toString()}`}><span dangerouslySetInnerHTML={{__html: paragraph}}/></Paragraph>
                        )}
                    </ModalDescription>
                    <FormLayoutButtons>
                        <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={onContinue}>
                            {textSites("modal.settings_explanation.button")}
                        </Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalSettingsExplanation;
