import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SitePrivacyUtm} from "./SitePrivacyUtm";
import {SitePrivacyClickId} from "./SitePrivacyClickId";
import {SitePrivacyReferer} from "./SitePrivacyReferer";
import {SitePrivacyTransactionId} from "./SitePrivacyTransactionId";
import {SitePrivacyTruncateIp} from "./SitePrivacyTruncateIp";
import {SitePrivacyUserAgent} from "./SitePrivacyUserAgent";
import {SitePrivacyUserId} from "./SitePrivacyUserId";
import {SitePrivacyUrl} from "./SitePrivacyUrl";

export class SitePrivacy extends ApiModel {
    truncate_ip: SitePrivacyTruncateIp = SitePrivacyTruncateIp.TRUNCATE_ONE_BYTE;
    user_id: SitePrivacyUserId = SitePrivacyUserId.PSEUDONYMISE;
    referer: SitePrivacyReferer = SitePrivacyReferer.NO_ALTER;
    url: SitePrivacyUrl = SitePrivacyUrl.PSEUDONYMISE_ALL_PARAMETERS;
    transaction_id: SitePrivacyTransactionId = SitePrivacyTransactionId.NO_ALTER;
    click_id: SitePrivacyClickId = SitePrivacyClickId.NO_ALTER;
    user_agent: SitePrivacyUserAgent = SitePrivacyUserAgent.NO_ALTER;
    utm: SitePrivacyUtm = new SitePrivacyUtm();
    ignore_tcf: boolean = false;
    domain_excluded_to_hash: boolean = false;
    disable_cookieless_tracking: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            truncate_ip: this.truncate_ip,
            user_id: this.user_id,
            referer: this.referer,
            url: this.url,
            transaction_id: this.transaction_id,
            click_id: this.click_id,
            user_agent: this.user_agent,
            utm: this.utm.getJsonParameters(),
            ignore_tcf: this.ignore_tcf,
            domain_excluded_to_hash: this.domain_excluded_to_hash,
            disable_cookieless_tracking: this.disable_cookieless_tracking
        };
    }
}

