import {Site} from "../model/site/Site";
import {Rest} from "../../common/api/rest/Rest";
import {SiteQuery} from "../model/site/search/SiteQuery";

export class RestSite extends Rest {
    getSites(query: SiteQuery): Promise<Site[]> {
        return this._client.get("analytics-helper/domain", Site, {queryParams: query}) as Promise<Site[]>;
    }

    getSite(siteId: string): Promise<Site> {
        return this._client.get("analytics-helper/domain/" + siteId, Site) as Promise<Site>;
    }

    create(site: Site): Promise<Site> {
        return this._client.post("/analytics-helper/domain", site, Site) as Promise<Site>;
    }

    update(site: Site) {
        return this._client.put("/analytics-helper/domain/" + site.id, site, Site) as Promise<Site>;
    }

    delete(siteId: string) {
        return this._client.delete("analytics-helper/domain/" + siteId);
    }
}
