import React, {FunctionComponent} from "react";
import {Action, ActionsMenu, Color, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Site} from "../../api/model/site/Site";
import {SiteStatus} from "../../api/model/site/SiteStatus";
import {TranslationPortalFile} from "../../utils/constants";

export type SiteTableRowActions = {
    onEditInfo: () => void;
    onEditSettings: () => void;
    onRetrieveCode: () => void;
    onDelete: () => void;
}

type SiteTableRowProps = {
    site: Site;
    actions: SiteTableRowActions;
}

const SiteTableRow: FunctionComponent<SiteTableRowProps> = ({site, actions}) => {
    const navigate = useNavigate();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSites} = useTranslation(TranslationPortalFile.SITES);

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                <StatusLabel label={textSites(`status.${site.status}`)} icon={{name: "circle", colorIcon: site.status === SiteStatus.ACTIVE ? Color.GREEN : Color.ORANGE}}/>
            </TableColumn>
            <TableColumn>{site.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{site.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{site.domain}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <ActionsMenu
                    iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                    items={[
                        {label: textSites("action.edit_info"), onClick: actions.onEditInfo},
                        {label: textSites("action.edit_settings"), onClick: actions.onEditSettings}
                    ]}
                />
                <ActionsMenu
                    iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                    items={[
                        {label: textSites("action.see_analytics"), onClick: () => navigate(site.getAnalyticsRoute())},
                        {label: textSites("action.retrieve_code"), onClick: actions.onRetrieveCode},
                        {label: textCommon(Action.DELETE.labelKey), onClick: actions.onDelete, separator: true, critical: true}
                    ]}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default SiteTableRow;
