import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalNew, ModalTitle} from "@sirdata/ui-lib";

type ModalConfigAccessProps = {
    active: boolean;
    onStart: () => void;
};

const ModalConfirmAccess: FunctionComponent<ModalConfigAccessProps> = ({active, onStart}) => {
    const {t: textAccess} = useTranslation(TranslationPortalFile.ACCESS);

    return (
        <ModalNew active={active}>
            <ModalContent>
                <ModalTitle>{textAccess("modal.confirm_access.title")}</ModalTitle>
                <FormLayoutRows>
                    <ModalDescription>{textAccess("modal.confirm_access.text")}</ModalDescription>
                    <FormLayoutButtons>
                        <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={onStart}>
                            {textAccess("modal.confirm_access.access_portal")}
                        </Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalConfirmAccess;
