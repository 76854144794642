import {ApiModel} from "../../../common/api/model/ApiModel";

export class StatHit extends ApiModel {
    date: string = "";
    hits: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
