export enum SiteSortOrder {
    DESCENDANT = "DESCENDANT",
    ASCENDANT = "ASCENDANT"
}

export type SortOrderItem = {
    type: SiteSortOrder;
    field: string;
}

export const SITE_SORT_ORDERS: SortOrderItem[] = [
    {type: SiteSortOrder.DESCENDANT, field: "desc"},
    {type: SiteSortOrder.ASCENDANT, field: "asc"}
];

export const defaultSortOrderItem = SITE_SORT_ORDERS[0];
