import {Alert, AlertSeverity, Box, Button, Color, Icon, LayoutColumns, SvgSirdataLogoWhite, Tag, TagStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {CONFIGURATION_MODES, ConfigurationModeName} from "../../utils/ConfigurationMode";
import {ConfigurationModeInformation} from "../../utils/ConfigurationModeInformation";
import {pathSites, TranslationPortalFile} from "../../utils/constants";
import {PortalQueryString} from "../../api/model/PortalQueryString";
import {Wrapper} from "../../common/component/widget";

function ConfigurationMode() {
    const navigate = useNavigate();
    const {t: textConfigurationMode} = useTranslation(TranslationPortalFile.CONFIGURATION_MODE);

    const CONFIGURATION_MODE_INFORMATIONS: { mode: ConfigurationModeName; info: ConfigurationModeInformation }[] = CONFIGURATION_MODES.map((mode) => {
        return {mode, info: textConfigurationMode(`modes.${mode}`, {returnObjects: true}) as ConfigurationModeInformation};
    });

    const handleConfigModeChoice = (mode: ConfigurationModeName) => {
        navigate(`${pathSites}?${PortalQueryString.SETUP}=${mode}`);
    };

    return (
        <Wrapper cssClass="onboarding">
            <div className="onboarding-page">
                <div className="onboarding-form onboarding-form--large">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <div className="title"><span>{textConfigurationMode("title")}</span></div>
                    <div className="description">{textConfigurationMode("description")}</div>
                    <LayoutColumns>
                        {CONFIGURATION_MODE_INFORMATIONS.map(({mode, info}) =>
                            <div key={mode}>
                                <Box cssClass="configuration-mode">
                                    <div className="configuration-mode__content">
                                        <div className="configuration-mode__content__header">
                                            <Tag
                                                label={info.time}
                                                style={TagStyle.DEFAULT_CYAN_LIGHT}
                                                customColor={{borderColor: Color.CYAN_LIGHT}}
                                                icon={{name: "timer", outlined: true}}
                                            />
                                        </div>
                                        <div className="configuration-mode__content__title">{info.title}</div>
                                        <div className="configuration-mode__content__description">{info.description}</div>
                                        <div className="configuration-mode__content__steps">
                                            {info.steps.map((step) =>
                                                <div key={step} className="configuration-mode__content__steps__item">
                                                    <Icon name="east" cssClass="configuration-mode__content__steps__item__icon"/>
                                                    <span>{step}</span>
                                                </div>
                                            )}
                                        </div>
                                        {info.indication && <Alert severity={AlertSeverity.INFO} text={info.indication} fullWidth/>}
                                    </div>
                                    <div>
                                        <Button onClick={() => handleConfigModeChoice(mode)} icon={{name: "east"}} reverse>{textConfigurationMode("setup")}</Button>
                                    </div>
                                </Box>
                            </div>
                        )}
                    </LayoutColumns>
                </div>
            </div>
        </Wrapper>
    );
}

export default ConfigurationMode;
