import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SitePrivacy} from "../../api/model/site/privacy/SitePrivacy";
import {TranslationPortalFile} from "../../utils/constants";
import {Site} from "../../api/model/site/Site";
import {AlertSeverity, ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentDirection, FormLayoutButtons, FormLayoutColumns, FormLayoutMention, FormLayoutRows, FormLayoutTitle, InputText, ModalContent, ModalNew, ToggleSwitch} from "@sirdata/ui-lib";
import {session} from "../../api/ApiSession";
import {GOOGLE_ANALYTICS_VERSIONS} from "../../api/model/site/GoogleAnalyticsVersion";
import {validateUrl} from "../../common/utils/string";
import {FormLayoutMessage} from "../../common/component/snippet";

type ModalCreateSiteProps = {
    active: boolean;
    onSubmit: (site: Site) => void;
    onClose: () => void;
};

type SiteForm = {
    name: string;
    domain: string;
    privacyPolicy: string;
    gaTid: string;
    cookieless: boolean;
};

type SiteFormErrors = {
    domain: boolean;
    privacyPolicy: boolean;
    gaTid: boolean;
};

const ModalCreateSite: FunctionComponent<ModalCreateSiteProps> = ({active, onSubmit, onClose}) => {
    const {t: textSites} = useTranslation(TranslationPortalFile.SITES);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [formErrors, setFormErrors] = useState<SiteFormErrors>();
    const [infoForm, setInfoForm] = useState<SiteForm>({name: "", domain: "", privacyPolicy: "", gaTid: "", cookieless: false});

    const hasEmptyField = () => !infoForm.domain || !infoForm.name || !infoForm.privacyPolicy;

    const handleChange = (name: string, value: string | boolean) => {
        setFormErrors({domain: false, privacyPolicy: false, gaTid: false});
        setInfoForm({...infoForm, [name]: value});
    };

    const handleClose = () => {
        setFormErrors({domain: false, privacyPolicy: false, gaTid: false});
        onClose();
    };

    const handleSubmit = async (e?: FormEvent) => {
        if (e) e.preventDefault();

        let form = {...infoForm};
        let formErrors: SiteFormErrors = {domain: false, privacyPolicy: false, gaTid: false};
        form.domain = new URL(form.domain.includes("http") ? form.domain : `https://${form.domain}`).hostname;
        formErrors.domain = !validateUrl(form.domain);
        if (!form.privacyPolicy.includes("http")) {
            form.privacyPolicy = `https://${form.privacyPolicy}`;
        }
        formErrors.privacyPolicy = !validateUrl(form.privacyPolicy);
        formErrors.gaTid = form.gaTid ? !GOOGLE_ANALYTICS_VERSIONS.find((it) => form.gaTid.startsWith(it.prefix)) : false;

        if (formErrors.domain || formErrors.privacyPolicy || formErrors.gaTid) {
            setFormErrors(formErrors);
            return;
        }

        setLoading(true);
        try {
            let newSite = new Site();
            newSite.load({
                name: form.name,
                domain: form.domain,
                privacy_policy: form.privacyPolicy,
                ga_tid: form.gaTid,
                privacy_configuration: new SitePrivacy({
                    disable_cookieless_tracking: !form.cookieless
                })
            });
            const created = await session.restSite.create(newSite);
            onSubmit(created);
        } catch (e) {
            console.error("Fail to create site:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setInfoForm({name: "", domain: "", privacyPolicy: "", gaTid: "", cookieless: false});
    }, [active]);

    return (
        <ModalNew onClose={handleClose} active={active}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>{textSites("modal.information.title")}</FormLayoutTitle>
                    <FormLayoutColumns>
                        <FieldBlock label={textSites("field.site_name")} required>
                            <InputText
                                placeholder={textSites("placeholder.site_name")}
                                value={infoForm.name}
                                onChange={(value) => handleChange("name", value)}
                                autoFocus
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textSites("field.domain")}
                            content={{direction: FlexContentDirection.COLUMN}}
                            required
                        >
                            <InputText
                                placeholder={textSites("placeholder.domain")}
                                value={infoForm.domain}
                                onChange={(value) => handleChange("domain", value.toLowerCase())}
                            />
                            {!!formErrors?.domain &&
                                <FormLayoutMessage message={textSites("error.invalid_domain")} severity={AlertSeverity.DANGER}/>
                            }
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FieldBlock
                        label={textSites("field.privacy_policy")}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputText
                            placeholder={textSites("placeholder.privacy_policy")}
                            value={infoForm.privacyPolicy}
                            onChange={(value) => handleChange("privacyPolicy", value.toLowerCase())}
                        />
                        {!!formErrors?.privacyPolicy &&
                            <FormLayoutMessage message={textSites("error.invalid_url")} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FieldBlock
                        label={textSites("field.ga_tid")}
                        tooltip={textSites("field.ga_tid_tooltip")}
                        content={{direction: FlexContentDirection.COLUMN}}
                    >
                        <InputText
                            placeholder={textSites("placeholder.ga_tid")}
                            value={infoForm.gaTid}
                            onChange={(value) => handleChange("gaTid", value)}
                        />
                        {!!formErrors?.gaTid &&
                            <FormLayoutMessage message={textSites("error.invalid_ga_tid")} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FieldBlock label={textSites("field.cookieless")} tooltip={textSites("field.cookieless_tooltip")}>
                        <ToggleSwitch
                            checked={infoForm.cookieless}
                            onChange={(value) => handleChange("cookieless", value)}
                        />
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={handleClose}/>
                        <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()} loading={isLoading}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalCreateSite;
