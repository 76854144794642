export enum SitePrivacyTruncateIp {
    NO_ALTER = "NO_ALTER",
    REMOVE = "REMOVE",
    TRUNCATE_ONE_BYTE = "TRUNCATE_ONE_BYTE",
    TRUNCATE_TWO_BYTE = "TRUNCATE_TWO_BYTE",
}

export const SITE_PRIVACY_TRUNCATE_IP_VALUES = [
    SitePrivacyTruncateIp.NO_ALTER,
    SitePrivacyTruncateIp.TRUNCATE_ONE_BYTE,
    SitePrivacyTruncateIp.TRUNCATE_TWO_BYTE,
    SitePrivacyTruncateIp.REMOVE
];
