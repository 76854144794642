export enum SitePrivacyUserId {
    NO_ALTER = "NO_ALTER",
    PSEUDONYMISE = "PSEUDONYMISE",
    PSEUDONYMISE_AND_KEEP_MAPPING = "PSEUDONYMISE_AND_KEEP_MAPPING",
}

export const SITE_PRIVACY_USER_ID_VALUES = [
    SitePrivacyUserId.NO_ALTER,
    SitePrivacyUserId.PSEUDONYMISE,
    SitePrivacyUserId.PSEUDONYMISE_AND_KEEP_MAPPING
];
