import {MenuItem} from "@sirdata/ui-lib";
import {pathAnalyticsHits, pathPrivacy, pathSites} from "./constants";
import {pathHome} from "../common/utils/constants";
import {sirdataHelperDocumentationUrl} from "./helper";

export enum Menu {
    HOME = "home",
    CONFIGURATION = "configuration",
    ANALYTICS = "analytics",
    PRIVACY = "privacy",
    DOCUMENTATION = "documentation"
}

export const MENU: MenuItem[] = [
    {label: Menu.HOME, path: pathHome, icon: {name: "home"}},
    {label: Menu.CONFIGURATION, path: pathSites, icon: {name:"settings"}},
    {label: Menu.ANALYTICS, path: pathAnalyticsHits, icon: {name:"bar_chart"}},
    {label: Menu.PRIVACY, path: pathPrivacy, icon: {name: "privacy_tip"}},
    {label: Menu.DOCUMENTATION, path: sirdataHelperDocumentationUrl, icon: {name: "description"}, externalUrl: true}
];
