import {Locale} from "../common/utils/Locale";

const isFrenchLocale = Locale.isFrench();

const sirdataHelperPricingUrl = isFrenchLocale
    ? "https://helper.docs.sirdata.net/sirdata-analytics-helper-1/tarification-analytics-helper"
    : "https://helper.docs.sirdata.net/v/english/sirdata-analytics-helper-1/pricing-analytics-helper";

const sirdataHelperProductUrl = isFrenchLocale
    ? "https://sirdata.com/fr/Analytics-Helper"
    : "https://sirdata.com/en/Analytics-Helper";

const sirdataHelperDocumentationUrl = isFrenchLocale
    ? "https://helper.docs.sirdata.net/"
    : "https://helper.docs.sirdata.net/v/english/";

const configGaTagsInGtmWithCookielessUrl = isFrenchLocale
    ? "https://helper.docs.sirdata.net/sirdata-analytics-helper-1/cas-de-balises-ga-dans-gtm-avec-fonctionnalite-cookieless"
    : "https://helper.docs.sirdata.net/v/english/sirdata-analytics-helper-1/case-of-ga-tags-in-gtm-with-cookieless-feature";

const configGaTagsInGtmWithoutCookielessUrl = isFrenchLocale
    ? "https://helper.docs.sirdata.net/sirdata-analytics-helper-1/cas-de-balises-ga-dans-gtm-sans-fonctionnalite-cookieless"
    : "https://helper.docs.sirdata.net/v/english/sirdata-analytics-helper-1/case-of-ga-tags-in-gtm-without-cookieless-feature";

const configPrivacyWithNonTcfCertifiedCmpUrl = isFrenchLocale
    ? "https://helper.docs.sirdata.net/sirdata-analytics-helper-1/prealable-obligatoire#3-avec-une-cmp-non-certifiee-tcf"
    : "https://helper.docs.sirdata.net/v/english/sirdata-analytics-helper-1/mandatory-prerequisite#3-with-a-non-tcf-certified-cmp";

export {
    sirdataHelperPricingUrl,
    sirdataHelperProductUrl,
    sirdataHelperDocumentationUrl,
    configGaTagsInGtmWithCookielessUrl,
    configGaTagsInGtmWithoutCookielessUrl,
    configPrivacyWithNonTcfCertifiedCmpUrl
};
