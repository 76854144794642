export enum SitePrivacyUrl {
    NO_ALTER = "NO_ALTER",
    PSEUDONYMISE_ALL_PARAMETERS = "PSEUDONYMISE_ALL_PARAMETERS",
    REMOVE_CLICK_ID_AND_UTM = "REMOVE_CLICK_ID_AND_UTM",
    REMOVE_ALL_PARAMETERS = "REMOVE_ALL_PARAMETERS"
}

export const SITE_PRIVACY_URL_VALUES = [
    SitePrivacyUrl.NO_ALTER,
    SitePrivacyUrl.PSEUDONYMISE_ALL_PARAMETERS,
    SitePrivacyUrl.REMOVE_CLICK_ID_AND_UTM,
    SitePrivacyUrl.REMOVE_ALL_PARAMETERS
];
