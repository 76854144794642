export enum SiteSortType {
    ID = "ID",
    NAME = "NAME",
    RECENCY = "RECENCY"
}

export type SiteSortTypeItem = {
    type: SiteSortType;
    field: string;
}

export const SITE_SORT_TYPES: SiteSortTypeItem[] = [
    {type: SiteSortType.RECENCY, field: "creation_ts"},
    {type: SiteSortType.NAME, field: "name"},
    {type: SiteSortType.ID, field: "id"}
];

export const defaultSiteSortTypeItem = SITE_SORT_TYPES[0];
