export enum SitePrivacyUserAgent {
    NO_ALTER = "NO_ALTER",
    KEEP_MAJOR_VERSION = "KEEP_MAJOR_VERSION",
    REMOVE = "REMOVE"
}

export const SITE_PRIVACY_USER_AGENT_VALUES = [
    SitePrivacyUserAgent.NO_ALTER,
    SitePrivacyUserAgent.KEEP_MAJOR_VERSION,
    SitePrivacyUserAgent.REMOVE
];
