import {Rest} from "../../common/api/rest/Rest";
import {StatHit} from "../model/stat/StatHit";

export class RestStat extends Rest {
    rootPath = "/analytics-helper/ga/stats";

    getDailyHits(start: string, end: string, configId?: number): Promise<StatHit[]> {
        return this._client.get(`${this.rootPath}/start/${start}/end/${end}`, StatHit, {queryParams: {conf_id: configId}}) as Promise<StatHit[]>;
    }
}
