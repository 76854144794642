import React from "react";
import {Accordion, AccordionItem, AccordionItemContent, Alert, Button, ButtonSize, CodeBlock, Paragraph} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {configPrivacyWithNonTcfCertifiedCmpUrl} from "../../utils/helper";
import {ContactTopic} from "../../common/api/model/account/ContactTopic";
import {ModalContactUiEvent} from "../../common/component/modal/ModalContact";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

type PrivacyRecommendationProps = {
    startOpen?: boolean;
}

const PrivacyRecommendation: React.FC<PrivacyRecommendationProps> = ({startOpen}) => {
    const {t: textInstallation} = useTranslation(TranslationPortalFile.INSTALLATION);
    const {t: textPrivacy} = useTranslation(TranslationPortalFile.PRIVACY);

    return (
        <Accordion>
            <AccordionItem heading={textPrivacy("cmp.sirdata.label")} startOpen={startOpen}>
                <AccordionItemContent>
                    {(textPrivacy("cmp.sirdata.paragraphs", {returnObjects: true}) as string[]).map((item, index) =>
                        <Paragraph key={`cmp-sirdata-p${index.toString()}`} withSpacing><span dangerouslySetInnerHTML={{__html: item}}/></Paragraph>
                    )}
                </AccordionItemContent>
            </AccordionItem>
            <AccordionItem heading={textPrivacy("cmp.tcf_compatible.label")} startOpen={startOpen}>
                <AccordionItemContent>
                    {(textPrivacy("cmp.tcf_compatible.paragraphs", {returnObjects: true}) as string[]).map((item, index) =>
                        <Paragraph key={`cmp-tcf_compatible-p${index.toString()}`} withSpacing>{<span dangerouslySetInnerHTML={{__html: item}}/>}</Paragraph>
                    )}
                    <div className="clickable" onClick={() => UIEventManager.emit(ModalContactUiEvent, {topicValue: ContactTopic.TECHNICAL_ISSUE})}>
                        <Alert text={textPrivacy("help_text")} fullWidth/>
                    </div>
                </AccordionItemContent>
            </AccordionItem>
            <AccordionItem heading={textPrivacy("cmp.tcf_incompatible.label")} startOpen={startOpen}>
                <AccordionItemContent>
                    {(textPrivacy("cmp.tcf_incompatible.paragraphs", {returnObjects: true}) as string[]).map((item, index) =>
                        <Paragraph key={`cmp-tcf_incompatible-p${index.toString()}`} withSpacing>{<span dangerouslySetInnerHTML={{__html: item}}/>}</Paragraph>
                    )}
                    <Paragraph withSpacing>
                        <Button size={ButtonSize.SMALL} onClick={() => window.open(configPrivacyWithNonTcfCertifiedCmpUrl, "_blank")}>{textInstallation("action.technical_documentation")}</Button>
                    </Paragraph>
                    <div className="clickable" onClick={() => UIEventManager.emit(ModalContactUiEvent, {topicValue: ContactTopic.TECHNICAL_ISSUE})}>
                        <Alert text={textPrivacy("help_text")} fullWidth/>
                    </div>
                </AccordionItemContent>
            </AccordionItem>
            <AccordionItem heading={textPrivacy("cmp.none.label")} startOpen={startOpen}>
                <AccordionItemContent>
                    {(textPrivacy("cmp.none.paragraphs", {returnObjects: true}) as string[]).map((item, index) =>
                        <Paragraph key={`cmp-none-p${index.toString()}`} withSpacing>{<span dangerouslySetInnerHTML={{__html: item}}/>}</Paragraph>
                    )}
                    <div className="paragraph">
                        <CodeBlock header={textPrivacy("recommendation.title")} code={textPrivacy("recommendation.text")} wrapLongLines copiable/>
                    </div>
                </AccordionItemContent>
                <AccordionItemContent>
                    <div className="clickable" onClick={() => UIEventManager.emit(ModalContactUiEvent, {topicValue: ContactTopic.TECHNICAL_ISSUE})}>
                        <Alert text={textPrivacy("help_text")} fullWidth/>
                    </div>
                </AccordionItemContent>
            </AccordionItem>
        </Accordion>
    );
};

export default PrivacyRecommendation;
