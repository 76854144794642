import {MainContentPageHeader, MainContentStyle, Paragraph} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {PrivacyRecommendation} from "../component/snippet";
import {TranslationPortalFile} from "../utils/constants";

function Privacy() {
    const {t: textPrivacy} = useTranslation(TranslationPortalFile.PRIVACY);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textPrivacy("title")}/>
                <Paragraph withSpacing>{textPrivacy("description")}</Paragraph>
                <PrivacyRecommendation startOpen/>
            </MainContent>
        </Wrapper>
    );
}

export default Privacy;
