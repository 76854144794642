import {ApiModel} from "../../../../common/api/model/ApiModel";
import {defaultSiteSortTypeItem} from "./SiteSortType";
import {defaultSortOrderItem} from "./SiteSortOrder";

export class SiteQuery extends ApiModel {
    sort_by: string = defaultSiteSortTypeItem.field;
    sort_order: string = defaultSortOrderItem.field;
    search: string = "";
    limit: number = 500;
    offset: number = 0;

    getJsonParameters(): {} {
        return {
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            search: this.search,
            limit: this.limit,
            offset: this.offset
        };
    }
}
