export enum SitePrivacyField {
    TRUNCATE_IP = "truncate_ip",
    USER_ID = "user_id",
    REFERER = "referer",
    URL = "url",
    TRANSACTION_ID = "transaction_id",
    CLICK_ID = "click_id",
    USER_AGENT = "user_agent",
    UTM = "utm",
    IGNORE_TCF = "ignore_tcf",
    DOMAIN_EXCLUDED_TO_HASH = "domain_excluded_to_hash",
    DISABLE_COOKIELESS_TRACKING = "disable_cookieless_tracking"
}
