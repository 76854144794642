import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Alert,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxShadow,
    BoxSpacing,
    Button,
    ButtonSize,
    ButtonStyle,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentMobileDirection,
    FlexContentProps,
    FlexContentSpacing,
    HeroHeader,
    Loadable,
    MainContentStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";

import {ActivationStatus} from "../common/utils/ActivationStatus";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {ModalConfirmAccess} from "../component/modal";
import {Origin} from "../common/api/model/Origin";
import {pathTermsAndConditions, TranslationPortalFile} from "../utils/constants";
import {pathAccess, pathLogin} from "../common/utils/constants";
import {PORTAL} from "../utils/Portal";
import {session} from "../api/ApiSession";
import {sirdataHelperPricingUrl, sirdataHelperProductUrl} from "../utils/helper";
import {usePreprod} from "../common/utils/helper";

type AccessForm = {
    termsAndConditions: boolean;
}

function Access() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t: textAccess} = useTranslation(TranslationPortalFile.ACCESS);
    const {t: textTranslation} = useTranslation(TranslationPortalFile.TRANSLATION);

    const [isLoading, setLoading] = useState(false);
    const [accessForm, setAccessForm] = useState<AccessForm>({termsAndConditions: false});

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const account = await session.getAccount();
                setLoading(false);
                if (!location.search && account.hasAccess(PORTAL.origin.name)) {
                    navigate(PORTAL.defaultPath);
                }
            } catch {
                navigate(pathLogin);
            }
        })();
    }, [navigate, location.search]);

    const isFormValidated = () => {
        return accessForm.termsAndConditions;
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH} cssClass="access">
                <HeroHeader title={textAccess("title")}/>
                <FlexContent {...FlexContentProps.LAYOUT_COLUMNS} alignment={FlexContentAlignment.CENTER} mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE} cssClass={"activation-section"}>
                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} cssClass="pricing">
                        <Box spacing={BoxSpacing.LARGE} cssClass="pricing__item">
                            <span className="h1">{textTranslation("solution.title")}</span>
                            <div className="pricing__item__tax">
                                <span>{textTranslation("solution.price_before")}</span>
                                <span className="pricing__item__tax__price">{textTranslation("solution.price")}</span>
                                <span>{textTranslation("solution.price_after")}</span>
                            </div>
                            <Alert text={textTranslation("free_trial")}/>
                        </Box>
                        <span dangerouslySetInnerHTML={{__html: textAccess("more_details", {sirdataHelperProductUrl, sirdataHelperPricingUrl})}}/>
                    </FlexContent>
                    <Loadable loading={isLoading}>
                        <Box radius={BoxRadius.MD} shadow={BoxShadow.BLACK_BIG} cssClass="activation-card">
                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.LARGE}>
                                {location.search.includes(ActivationStatus.ERROR) &&
                                    <Alert
                                        text={textAccess("error.activation")}
                                        severity={AlertSeverity.DANGER}
                                        onClose={() => navigate(pathAccess, {replace: true})}
                                        fullWidth
                                    />
                                }
                                <span className="h1 activation-card__title" dangerouslySetInnerHTML={{__html: textAccess("text")}}/>
                                <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                                    <ToggleSwitch
                                        onChange={(value) => setAccessForm((prevState) => ({...prevState, termsAndConditions: value}))}
                                        checked={accessForm.termsAndConditions}
                                    />
                                    <span className="activation-card__terms" dangerouslySetInnerHTML={{__html: textAccess("items.terms_and_conditions", {termsAndConditionsUrl: pathTermsAndConditions})}}/>
                                </FlexContent>
                                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.END}>
                                    <Button
                                        size={ButtonSize.BIG}
                                        style={ButtonStyle.PRIMARY_GREEN}
                                        onClick={() => window.location.href = Origin.ACCOUNT.getUrl(usePreprod) + `/activate-service?origin=${PORTAL.origin.name}`}
                                        disabled={!isFormValidated()}
                                    >
                                        {textAccess("activate_product")}
                                    </Button>
                                </FlexContent>
                            </FlexContent>
                        </Box>
                    </Loadable>
                </FlexContent>
                <ModalConfirmAccess active={location.search.includes(ActivationStatus.SUCCESS)} onStart={() => navigate(PORTAL.defaultPath)}/>
            </MainContent>
        </Wrapper>
    );
}

export default Access;
